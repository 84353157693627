<template>
	<div>
		<VueHtml2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="false"
			:preview-modal="true"
			:paginate-elements-by-height="1400"
			:filename="filename"
			:pdf-quality="2"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			ref="html2Pdf"
		>

		<!-- 
			:html-to-pdf-options="options"
			pdf-content-width="800px"
			:paginate-elements-by-height="1400" -->

			<template slot="pdf-content">
				<v-app>
					<v-main>
						<!-- <div style="margin: 12px 12px"></div> -->
    					<!-- <v-container > -->

							<div class="mx-8">
								<v-avatar size="48px">
									<img :src="logo">
								</v-avatar>
								<span class="ml-4 text-h4">{{ logoTitle }}</span>

								<h2 class="text-center">{{ title }}</h2>

								<v-row no-gutters>
									<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
										№ {{ id }}
									</v-col>
									<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right">
										{{ item.datecreate | formatDateTime }}
									</v-col>
									<v-col cols="12" class="text-right">
										{{ username }}
									</v-col>
								</v-row>
								<br>
								<hr>
								<br>
							</div>

							<div class="mx-8" v-if="parameters.length">
								<h3>Параметри запиту</h3><br>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Назва</th>
												<th class="text-left">Значення</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in parameters" :key="index">
												<td>{{ item.name }}</td>
												<td>{{ item.value }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<div class="html2pdf__page-break" v-if="registrations.length"/>

							<div class="my-8 mx-8" v-if="registrations.length">
								<h3>Транспортний засіб</h3><br>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Назва</th>
												<th class="text-left">Значення</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in registrations" :key="item.code">
												<td>{{ item.name }}</td>
												<td>{{ item.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<div class="html2pdf__page-break" v-if="estimates.length"/>

							<div class="my-8 mx-8" v-if="estimates.length">
								<h3>Оцінка ринку</h3><br>
								<!-- <h3>Оцінка</h3><br> -->
								<div v-for="estimate in estimates" :key="estimate.id">
									<v-simple-table dense v-if="estimate.total">
										<template v-slot:default>
											<tbody>
												<tr>
													<td>Середнє арифметичне</td>
													<td class="text-right">{{ parseInt(estimate.arithmeticMean) }}</td>
												</tr>
												<tr>
													<td>Середнє арифметичне без урахування 25% найменших і найбільших значень</td>
													<td class="text-right">{{ parseInt(estimate.interQuartileMean) }}</td>
												</tr>
												<tr>
													<td>Загальна кількість оголошень, що беруть участь у підрахунку</td>
													<td class="text-right">{{ estimate.total }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>

									<h5 v-if="estimate.prices.length">Статистика</h5>
									<v-simple-table dense v-if="estimate.prices.length">
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left"></th>
													<th class="text-right">UAH</th>
													<th class="text-right">USD</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Середня ціна</td>
													<td class="text-right">{{ avgUAH(estimate.prices) | toCurrencyUAH }}</td>
													<td class="text-right">{{ avgUSD(estimate.prices) | toCurrencyUSD }}</td>
												</tr>
												<tr>
													<td>Мінімальна ціна</td>
													<td class="text-right">{{ minUAH(estimate.prices) | toCurrencyUAH }}</td>
													<td class="text-right">{{ minUSD(estimate.prices) | toCurrencyUSD }}</td>
												</tr>
												<tr>
													<td>Максимальна ціна</td>
													<td class="text-right">{{ maxUAH(estimate.prices) | toCurrencyUAH }}</td>
													<td class="text-right">{{ maxUSD(estimate.prices) | toCurrencyUSD }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
									<br>

									<h5 v-if="estimate.prices.length">Вартість за періодами</h5>
									<v-simple-table dense v-if="estimate.prices.length">
										<template v-slot:default>
											<thead>
												<tr>
													<th>Дата</th>
													<th class="text-right">UAH</th>
													<th class="text-right">USD</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in estimate.prices" :key="index">
													<td >{{ item.name }}</td>
													<td class="text-right">{{ item.priceUAH | toCurrencyUAH }}</td>
													<td class="text-right">{{ item.priceUSD | toCurrencyUSD }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>

									<br>

									<h5 v-if="estimate.percentiles.length">Процентілі</h5>
									<v-simple-table dense v-if="estimate.percentiles.length">
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left" v-for="percentile in estimate.percentiles" :key="percentile.id" >{{ percentile.code }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td v-for="percentile in estimate.percentiles" :key="percentile.id">{{ parseInt(percentile.price) }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>

									<br>

									<h4 v-if="estimate.adverts.length">Ціни за об'явами</h4>
									<v-simple-table dense v-if="estimate.adverts.length">
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">Об'ява</th>
													<th class="text-left">Ціна</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="advert in adverts(estimate.adverts)" :key="advert.id">
													<td>{{ advert.code }}</td>
													<td>{{ parseInt(advert.price) }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>

									<h4 v-if="estimate.equipments.length">Комплектація</h4>
									<v-simple-table dense v-if="estimate.equipments.length">
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">Код</th>
													<th class="text-left">Назва</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(equipment, index) in estimate.equipments" :key="index">
													<td>{{ equipment.name }}</td>
													<td>{{ equipment.description }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</div>
							</div>

							<div class="html2pdf__page-break" v-if="increas.length || reduce.length  || elements.length"/>

							<div class="my-8 mx-8" v-if="increas.length">
								<h3>Фактори, що впливають на процент збільшення вартості КТЗ</h3>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
												<th width="20%" class="text-left">Дз, %</th>
												<!-- <th width="30%" class="text-left">Опис</th> -->
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in increas" :key="index" >
												<td>{{ item.name }}</td>
												<td>{{ item.type.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<br>

							<div class="my-8 mx-8" v-if="reduce.length">
								<h3>Фактори, що впливають на процент зменшення вартості КТЗ</h3>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
												<th width="20%" class="text-left">Дз, %</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in reduce" :key="index" >
												<td>{{ item.name }}</td>
												<td>{{ item.type.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<br>

							<div class="my-8 mx-8" v-if="elements.length">
								<h3>Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами</h3>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування елементів, складових частин КТЗ</th>
												<th width="20%" class="text-left">Дз, %</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in elements" :key="index" >
												<td>{{ item.name }}</td>
												<td>{{ item.type.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<!-- <div class="html2pdf__page-break" v-if="(registrations.length || estimates.length || increas.length || reduce.length  || elements.length) && factors.length"/> -->
							<div class="html2pdf__page-break" v-if="factors.length"/>

							<div class="my-8 mx-8" v-if="factors.length">
								<h3>Розрахунок вартості</h3><br>
								<p class="text-subtitle-2">
										На основі аналізу довідкових даних щодо даного транспортного засобу встановлені такі показники:
								</p>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="50%" class="text-left">Показник</th>
												<th width="10%" class="text-left">Умовне позначення</th>
												<th width="10%" class="text-left">Значення</th>
												<th width="30%" class="text-left">Опис</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in factors" :key="item.id" >
												<td>{{ item.name }}</td>
												<td>{{ item.code }}</td>
												<td>{{ item.val }}</td>
												<td>{{ item.description }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>

								<br>

								<h3>ВИСНОВОК</h3>
								<v-simple-table>
									<template v-slot:default>
										<tbody>
											<tr>
												<td width="70%">Середньоринкова вартість ТЗ становить:</td>
												<td class="d-flex justify-end align-center">{{ item.costAmvUAH | toCurrencyUAH }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<!-- <footer>
								<br>
								<hr>
								<p style="text-align: center;">© 2024 Ortica Systems</p>
							</footer> -->
						<!-- </v-container> -->
					</v-main>	
				</v-app>
			</template>
		</VueHtml2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
/*
https://www.npmjs.com/package/vue-html2pdf
*/
export default {
	components: {
        VueHtml2pdf
    },
	data () {
		return {
			logo: require('../../assets/logo.svg'),
			logoTitle: 'Ortica Systems',
			
			options: {
				//margin: [10, 10],
			
				//filename: `OrticaSystems.pdf`,
			
				image: {
					type: 'jpeg', 
					quality: 0.98
				},
			
				enableLinks: false,
			
				html2canvas: {
					scale: 1,
					useCORS: true
				},
			
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				}
			}
		}
	},
	props: {
		item: 		{ type: Object, default: () => {} },
	},

	computed: {
		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		title() {
			return this.item?.type?.name ? this.item.type.name: '';
		},

		filename() {
			return this.title + ' ' + this.id
		},

		username() {
			return this.item?.user?.name ? this.item.user.name: '';
		},

		parameters() {
			//console.log('pdf', 'VehicleEstimate.vue', 'parameters','item', this.item)

			let result = []
			//------------------------------------------
			if(this.item?.number) result.push({name: "Реєстраційний номер", value: this.item.number})
			if(this.item?.vin) result.push({name: "VIN-код", value: this.item.vin})
			if(this.item?.category?.name) result.push({name: "Категорія", value: this.item.category.name})
			if(this.item?.brand?.name) result.push({name: "Марка", value: this.item.brand.name})
			if(this.item?.model?.name) result.push({name: "Модель", value: this.item.model.name})
			if(this.item?.yearProd) result.push({name: "Рік випуску авто", value: this.item.yearProd})
			if(this.item?.year) result.push({name: "Рік випуску авто", value: this.item.year})
			if(this.item?.generation?.name) result.push({name: "Покоління", value: this.item.generation.name})
			if(this.item?.modification?.name) result.push({name: "Модифікація", value: this.item.modification.name})
			if(this.item?.bodystyle?.name) result.push({name: "Тип кузова", value: this.item.bodystyle.name})
			if(this.item?.driver?.name) result.push({name: "Тип приводу", value: this.item.driver.name})
			if(this.item?.fuel?.name) result.push({name: "Тип палива", value: this.item.fuel.name})
			if(this.item?.gearbox?.name) result.push({name: "Коробка передач", value: this.item.gearbox.name})
			if(this.item?.options?.length) {
				const arr = this.item.options.map(object => object.name);
				const value = arr.join(', ')
				result.push({name: "Опції", value: value})
			}
			if(this.item?.color?.name) result.push({name: "Колір", value: this.item.color.name})
			if(this.item?.country?.name) result.push({name: "Країна виробник", value: this.item.country.name})
			if(this.item?.state?.name) result.push({name: "Область", value: this.item.state.name})
			if(this.item?.city?.name) result.push({name: "Місто", value: this.item.city.name})
			if(this.item?.mileageFrom || this.item?.mileageTo) {
				result.push({name: "Пробіг, тис.км", value: (this.item?.mileageFrom ? this.item?.mileageFrom : '...') + ' - ' + (this.item?.mileageTo ? this.item?.mileageTo : '...')})
			}
			if(this.item?.mileage?.length) result.push({name: "Пробіг, тис.км", value: this.item.mileage})
			if(this.item?.engineVolumeFrom || this.item?.engineVolumeTo) {
				result.push({name: "Об'єм двигуна, л.", value: (this.item?.engineVolumeFrom ? this.item?.engineVolumeFrom : '...') + ' - ' + (this.item?.engineVolumeTo ? this.item?.engineVolumeTo : '...')})
			}
			if(this.item?.engineVolume) result.push({name: "Об'єм двигуна", value: this.item.engineVolume})
			
			//Параметри для методики
			if(this.item?.odometer) result.push({name: "Показник одометра, км", value: this.item.odometer})
			if(this.item?.mileageAvgNorm) result.push({name: "Нормативний середньорічний пробіг, км", value: this.item.mileageAvgNorm})
			if(this.item?.avgPriceUAH) result.push({name: "Середня ринкова ціна", value: this.item.avgPriceUAH})
			if(this.item?.marketregion?.name) result.push({name: "Ринок регіону", value: this.item.marketregion?.name})
			if(this.item?.bargain) result.push({name: "Коефіцієнт торгу", value: this.item.bargain})

			
			return result
		},

		// Дані про державну реєстрацію транспортного засобу
		registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},

		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},

		// Фактори, що впливають на процент збільшення вартості КТЗ
		increas() {
			//return this.item.hasOwnProperty('increas') ? this.item.increas: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => item.type.growing && !item.type.element ): [];
		},

		// Фактори, що впливають на процент зниження вартості КТЗ
		reduce() {
			//return this.item.hasOwnProperty('reduce') ? this.item.reduce: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && !item.type.element ): [];
		},

		// Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами
		elements() {
			//return this.item.hasOwnProperty('elements') ? this.item.elements: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && item.type.element ): [];
		},

		factors() {
			return this.item.hasOwnProperty('factors') ? this.item.factors: [];
		},
	},

	methods: { 
		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

        generate () {
			//console.log('\\pdf\\VehicleEstimate.vue', 'generate', 'item', this.item)

            this.$refs.html2Pdf.generatePdf()
        },

		valueUAH(items) {
			return items.map(element=>element.priceUAH);
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},

		avgUAH(items) {
			const arr = this.valueUAH(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		avgUSD(items) {
			const arr = this.valueUSD(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		minUAH(items) {
			const arr = this.valueUAH(items);
			return Math.min(...arr)
		},
		minUSD(items) {
			const arr = this.valueUSD(items);
			return Math.min(...arr)
		},

		maxUAH(items) {
			const arr = this.valueUAH(items);
			return Math.max(...arr)
		},
		maxUSD(items) {
			const arr = this.valueUSD(items);
			return Math.max(...arr)
		},
    },

}

</script>

<style>

</style>