<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<z-vehicle-parameters-number
						vin-default="WAUZZZ8E03A008399"
					></z-vehicle-parameters-number>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<!-- <v-btn icon   v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">
									<v-icon>mdi-file-pdf-box</v-icon>
								</v-btn> -->
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<!-- <v-card v-if="visibleAdverts && adverts.length" -->
			<!-- <v-card v-if="adverts.length" -->
			<!-- <v-card
				class="mx-auto"
				elevation="0"
			>
				<v-card-title class="text-h6">Об'яви</v-card-title>
				<v-card-text v-for="estimate in item.estimates" :key="estimate.id">
					<v-chip-group active-class="primary--text" column>
						<v-chip v-for="(advert, index) in estimate.adverts" :key="index" 
							:href="getAdvertUrl(advert)"
							target="_blank"
						>
							{{ parseInt(advert.price) }}
						</v-chip>
					</v-chip-group>
				</v-card-text>
			</v-card> -->
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }} {{ id ? '№' + id: '' }}
				</v-card-title>
				<v-card-subtitle>{{ subtitle }}</v-card-subtitle>

				<v-divider></v-divider>

				<v-row dense v-for="estimate in item.estimates" :key="estimate.id">
					<v-col v-for="advert in estimate.adverts" :key="advert.id" cols="4">
						<v-card
							:loading="loading"
							class="mx-auto my-12"
							max-width="374"
						>
							<template slot="progress">
								<v-progress-linear
									color="deep-purple"
									height="10"
									indeterminate
								></v-progress-linear>
							</template>

							<v-img height="250" :src="advert.image"></v-img>

							<v-card-title>{{ advert.name }}</v-card-title>
							<v-card-subtitle align="left">{{ advert.description }}</v-card-subtitle>

							<v-card-text>
								<!-- <div class="text-subtitle-1">{{ advert.description }}</div> -->

								<!-- <div>{{ advert.description }}</div> -->
								<div>{{ advert.dateReg | formatDate }}</div>
							</v-card-text>

							<v-divider class="mx-4"></v-divider>

							<v-card-title>Ціни</v-card-title>

							<v-card-text>
								<v-chip-group active-class="deep-purple accent-4 white--text" column>
									<v-chip>{{ advert.priceUAH | toCurrencyUAH  }}</v-chip>
									<v-chip>{{ advert.priceUSD | toCurrencyUSD  }}</v-chip>
								</v-chip-group>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="deep-purple lighten-2"
									text
									:href="getAdvertUrl(advert)"
									target="_blank"
								>
									Оголошення
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>

				<v-divider></v-divider>

				<v-card-title class="font-weight-light">
					Інформацію можна пошукати на наступних ресурсах
				</v-card-title>

				<v-container class="pa-4 text-center">
					<v-row class="fill-height" align="center" justify="center">
						<template v-for="(item, i) in resources">
							<v-col :key="i" cols="12" md="4">
								<v-hover v-slot="{ hover }">
									<v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" 
										:color="item.backgroundColor"
										:href="item.href" target="_blank"
										:dark="item.dark"
									>
										<!-- <v-img :src="item.image" height="85px"> -->
											<!-- <v-card-title class="text-h6 white--text">
												<v-row class="fill-height flex-column" justify="space-between">
													<p class="mt-4 subheading text-left">
														{{ item.title }}
													</p>
												</v-row>
											</v-card-title> -->
											<!-- <v-card-title class="text-h6">{{ item.title }}</v-card-title>
											<v-card-subtitle>{{ item.subtitle }}</v-card-subtitle> -->

										<!-- </v-img> -->

										<v-card-title class="text-h6">{{ item.title }}</v-card-title>
										<v-card-subtitle align="left">{{ item.subtitle }}</v-card-subtitle>
									</v-card>
								</v-hover>
							</v-col>
						</template>
					</v-row>
				</v-container>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf"
			:item="item"
		></z-pdf>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import downloadExcel from "vue-json-excel"
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zPdf from '@/views/pdf/VehicleEstimate'

export default {
	name: 'VehicleAutoAdvert',
	components: {
		zPdf,
		downloadExcel,
		zVehicleParametersNumber,
	},
	data: () => ({
		title: 'Оголошення з продажу транспортного засобу',
		subtitle: '',

		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.regnum ? ' ' + this.params.regnum : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excelType)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		resources() {
			return [
				/* { title: 'BidFax', href: 'https://bidfax.info', image: 'https://bidfax.info/templates/ru/images/logo.png', backgroundColor: "#6A7189" }, */
				{ title: 'BidFax', subtitle: 'Інформація з американських страхових аукціонів пошкоджених автомобілів Copart и IAAI', href: 'https://bidfax.info', image: null, backgroundColor: '#6A7189', dark: true },
				{ title: 'AutoZona', subtitle: 'Інформація про автомобілі з українських та закордоних торгівельних майданчиків', href: 'https://autozona.com.ua', image: null, backgroundColor: '#6c757d', dark: true },
				{ title: 'Vf.vin', subtitle: 'Історія автомобілів з аукціоннів США, ОАЕ, Кореї, Європи (COPART, IAAI, Manheim, Emiratesauction) та інших країн світу', href: `https://vf.vin/search?${this.params.vin ? 'vin=' + this.params.vin : ''}`, image: null, backgroundColor: '#007aff', dark: true },
				{ title: 'EpicVIN', subtitle: 'Повний звіт про історію транспортних засобів з 2012 року. Сертифікований постачальник даних NMVTIS', href: 'https://epicvin.com', image: null, backgroundColor: '#eff7ff', dark: false },				
				{ title: 'Державна митна служба України', subtitle: 'Перевірка автомобіля за VIN на предмет наявності його митного оформлення в Україні з 2014 року', href: 'https://cabinet.customs.gov.ua/vincheck', image: null, backgroundColor: '#315e8e', dark: true },
				{ title: 'МТСБУ', subtitle: 'Пошук чинного полісу за VIN-кодом транстпортного засобу', href: 'https://policy.mtsbu.ua/?SearchType=Contract', image: null, backgroundColor: '#398450', dark: true },
				{ title: 'BIDCARS', subtitle: 'Купівля i доставка американських автомобілів', href: 'https://bid.cars/ua/', image: null, backgroundColor: '#334150', dark: true },
			]
		},

		/* adverts() {
			let result = []
			console.log(this.$options.name, 'adverts','item', this.item)

			if(this.item.hasOwnProperty('estimates')) {
				console.log(this.$options.name, 'adverts','estimates', this.item.estimates)
				if(this.item.estimates.hasOwnProperty('adverts')) {
					console.log(this.$options.name, 'adverts','result', this.item.estimates.adverts)
					//const sorted = [...this.item.estimates.adverts].sort((a, b) => parseInt(a.price) - parseInt(b.price));
					//result = sorted
					result = this.item.estimates.adverts
				}
			}
			return result;
		}, */

		// Дані про державну реєстрацію транспортного засобу
		/* registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		}, */
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		validate() {
			return this.params.number || this.params.vin
		},

		onClear() {
			this.clear()
		},

		onOK() {
			var that = this

			if(this.validate()) {
				this.loading = true
				axios.post('/vehicle/estimate/auto/advert', this.params).then(response => {
					console.log(that.$options.name, 'onOK','response.data', response.data)

					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => (this.loading = false));
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		},

		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)
			//$uri = item.uri ? item.uri : `/auto_${this.brand}_${this.model}_${item.code}.html`;

			//return item.uri `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
			return `https://auto.ria.com/uk${item.uri}`
		},
	},
}
</script>