<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>{{ icon }}</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar extended dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<!-- <z-detail-script
					:item="item"
				></z-detail-script> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-container>
						<v-toolbar flat dark color="transparent">
							<v-spacer></v-spacer>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-menu
										v-model="datebeginMenu"
										:close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="datebegin"
											label="Дата початку періоду"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
											hide-details="auto"
										></v-text-field>
										</template>
										<v-date-picker
										v-model="datebegin"
										@input="datebeginMenu = false"
										></v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-menu
										v-model="dateendMenu"
										:close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="dateend"
											label="Дата завершення періоду"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
											hide-details="auto"
										></v-text-field>
										</template>
										<v-date-picker
										v-model="dateend"
										@input="dateendMenu = false"
										></v-date-picker>
									</v-menu>
								</v-col>
							</v-row>

							<v-btn icon @click.stop="find">
								<v-icon>mdi-refresh</v-icon>
							</v-btn>
						</v-toolbar>
					</v-container>
				</template>

			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>Кількість запитів за період</td>
									<td >{{ countByPeriod }}</td>
								</tr>
								<tr>
									<td>Всього запитів</td>
									<td >{{ item.cnt ? item.cnt : 0 }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<br>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Тип запиту</th>
									<th>Кількість</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in grouptypes" :key="index">
									<td >{{ item.name }}</td>
									<td >{{ item.cnt }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<br>
					<v-card class="mx-auto" color="grey lighten-4" v-if="charts.length">
						<v-card-title>
							<div class="text-caption grey--text text-uppercase">
								Динаміка
							</div>
							<v-spacer></v-spacer>
							<!-- <strong>{{ item.cnt }}</strong> -->
						</v-card-title>

						<v-sheet color="transparent">
							<v-sparkline
								:labels="chartLabels"
								:value="chartValues"
								color="blue"
								line-width="1"
								padding="16"
								label-size="3"
							>
								<!-- <template v-slot:label="{ index, value }"> -->
									<!-- <span v-if="item.label.getDate()==1">{{ item.label.getMonth() }}.{{ item.label.getDate() }}</span>
									<span v-else>{{ item.label.getDate() }}</span> -->
									<!-- <span>{{ value }}</span> -->
								<!-- </template> -->
							</v-sparkline>
						</v-sheet>
					</v-card>

					<v-simple-table dense v-if="charts.length">
						<template v-slot:default>
							<thead>
								<tr>
									<th>Дата</th>
									<th>Кількість</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in charts" :key="index">
									<td >{{ item.d }}</td>
									<td >{{ item.cnt }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-container>
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'

export default {
	name: 'UsersStatistic',
	components: {},
	data: () => ({
		title: 'Статистика запитів користувача',
		icon: 'mdi-chart-timeline-variant-shimmer',
		dialog: false,
		loading: false,
		
		item: {},

		datebegin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		datebeginMenu: false,
		dateend: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		dateendMenu: false,
	}),

	props: {
		//item: 					{ type: Object, default: {} },
		selected: 					{ type: Object, default: {} },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу
		tooltip:  					{ type: String, default: 'statistic' },
	},

	computed: {
		charts() {
			return this.item?.items ? this.item.items : []
		},

		grouptypes() {
			return this.item?.grouptypes ? this.item.grouptypes : []
		},

		chartLabels() {
			return this.charts.map(element => (new Date(element.d)).getDate());
			//return this.item.items.map(element=> (new Date(element.d)));
		},

		chartValues() {
			return this.charts.map(element => parseInt(element.cnt));
		},

		countByPeriod() {
			//const prices = shoppingCart.map((product) => product.price)
			if(this.chartValues.length) {
				const total = this.chartValues.reduce((acc, curr) => acc + curr)
				//console.log('total: ', total )
				return total
			}

			return 0
		},
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.find()
			}
		},
	},

	created() {
		var dateend = new Date(), datebegin = new Date();
		//datebegin.setDate(datebegin.getDate() - 1) // day ago
		datebegin.setMonth(datebegin.getMonth() - 1) // month ago

		this.datebegin = (new Date(datebegin - datebegin.getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		this.dateend = (new Date(dateend - dateend.getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
	},

	methods: {
		/**
		 * Пошук
		 */
		find () {
			if(this.selected?.id) {

				let params = {}
				if(this.datebegin) 	params['datebegin'] = this.datebegin
				if(this.dateend) 	params['dateend'] = this.dateend

				//https://api.ortica-systems.com/medat/organization/1/statistic?datebegin=2024-10-15&dateend=2024-10-20

				//console.log(this.$options.name, 'find','params', params)

				this.loading = true;
				var that = this;
				axios.get(`/users/${this.selected.id}/statistic`, { params: params }).then(response => {
					//console.log(this.$options.name, 'find','response.data', response.data)
					that.item = Object.assign({}, response.data)
				}, error => {
					console.log(error)
				}).finally(() => (this.loading = false));
			}
		},
	},
}
</script>