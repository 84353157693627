<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<!-- <v-card class="mx-auto" elevation="0"> -->
			<v-card elevation="0">
				<v-card-text>
					<!-- <z-vehicle-parameters-number></z-vehicle-parameters-number> -->
					<v-form ref="form">
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									label="Реєстраційний номер"
									v-model.trim="params.number"
									outlined
									dense
									clearable
									@keyup.ctrl.90="params.number='ab9999it'"
									hide-details="auto"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									label="VIN-код"
									v-model.trim="params.vin"
									outlined
									dense
									clearable
									@keyup.ctrl.90="params.vin='WAUZZZ4M0HD001505'"
									hide-details="auto"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<!-- <v-btn icon   v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">
									<v-icon>mdi-file-pdf-box</v-icon>
								</v-btn> -->
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card flat>
				<v-card-title class="font-weight-light">
					{{ title }} {{ id ? '№' + id: '' }}
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text>
					<v-row>
						<v-col cols="12" md="8">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left" width="50%">Назва</th>
											<th class="text-left">Значення</th>
										</tr>
									</thead>
									<tbody>
										<!-- <tr v-for="item in registrations" :key="item.code">
											<td>{{ item.name }}</td>
											<td>{{ item.val }}</td>
										</tr> -->
										<tr v-if="item.hasOwnProperty('digits')"><td>Номер</td><td>{{ item.digits }}</td></tr>
										<tr v-if="item.hasOwnProperty('vin')"><td>VIN</td><td>{{ item.vin }}</td></tr>
										<tr v-if="item.hasOwnProperty('region')"><td>Регіон</td><td>{{ item.region.name_ua }}</td></tr>
										<tr v-if="item.hasOwnProperty('vendor')"><td>Марка</td><td>{{ item.vendor }}</td></tr>
										<tr v-if="item.hasOwnProperty('model')"><td>Модель</td><td>{{ item.model }}</td></tr>
										<tr v-if="item.hasOwnProperty('model_year')"><td>Рік</td><td>{{ item.model_year }}</td></tr>
										<tr v-if="item.hasOwnProperty('notes')"><td>Примітки</td><td>{{ item.notes }}</td></tr>
										<tr v-if="item.hasOwnProperty('is_stolen')"><td>Ознака викрадення</td><td>{{ item.is_stolen ? 'ТАК' : 'НІ' }}</td></tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						<v-col cols="12" md="4">
							<v-img v-if="item.hasOwnProperty('photo_url')"
								:src="item.photo_url"
							></v-img>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<!-- <v-divider></v-divider> -->

			<!-- <v-card flat>
				<v-card-title class="font-weight-light text-subtitle-1">Реєстрація</v-card-title>
				<v-card-text v-for="(item, index) in operations" :key="index">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left" width="30%">Назва</th>
									<th class="text-left">Значення</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="item.hasOwnProperty('digits')"><td>Номер</td><td>{{ item.digits }}</td></tr>
								<tr v-if="item.hasOwnProperty('operation_group')"><td>Тип реєстрації</td><td>{{ item.operation_group.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('is_last')"><td>Остання реєстрація</td><td>{{ item.is_last? 'ТАК' : 'НІ' }}</td></tr>
								<tr v-if="item.hasOwnProperty('registered_at')"><td>Дата реєстрації</td><td>{{ item.registered_at }}</td></tr>
								
								<tr v-if="item.hasOwnProperty('catalog_model_title')"><td>catalog_model_title</td><td>{{ item.catalog_model_title }}</td></tr>
								<tr v-if="item.hasOwnProperty('catalog_model_slug')"><td>catalog_model_slug</td><td>{{ item.catalog_model_slug }}</td></tr>
								
								<tr v-if="item.hasOwnProperty('vendor')"><td>Марка</td><td>{{ item.vendor }}</td></tr>
								<tr v-if="item.hasOwnProperty('model')"><td>Модель</td><td>{{ item.model }}</td></tr>
								<tr v-if="item.hasOwnProperty('model_year')"><td>Рік</td><td>{{ item.model_year }}</td></tr>
								<tr v-if="item.hasOwnProperty('kind')"><td>Тип</td><td>{{ item.kind.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('body')"><td>Тип кузова</td><td>{{ item.body.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('purpose')"><td>Призначення</td><td>{{ item.purpose.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('color')"><td>Колір</td><td>{{ item.color.ua }}</td></tr>

								<tr v-if="item.hasOwnProperty('is_registered_to_company')"><td>Зареєстровано на організацію</td><td>{{ item.is_registered_to_company? 'ТАК' : 'НІ' }}</td></tr>
								<tr v-if="item.hasOwnProperty('department')"><td>Підрозділ</td><td>{{ item.department }}</td></tr>
								<tr v-if="item.hasOwnProperty('address')"><td>Адреса</td><td>{{ item.address }}</td></tr>
								<tr v-if="item.hasOwnProperty('koatuu')"><td>КОАТУУ</td><td>{{ item.koatuu }}</td></tr>
								<tr v-if="item.hasOwnProperty('displacement')"><td>displacement</td><td>{{ item.displacement }}</td></tr>
								<tr v-if="item.hasOwnProperty('operation')"><td>Коментар</td><td>{{ item.operation.ua }}</td></tr>
							</tbody>
						</template>
					</v-simple-table>
					<br>
					<v-divider></v-divider>
				</v-card-text>
			</v-card> -->

			<v-card flat v-for="(item, index) in operations" :key="index">
				<v-card-title class="font-weight-light text-subtitle-1">Реєстрація {{ item.registered_at }}</v-card-title>
				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left" width="30%">Назва</th>
									<th class="text-left">Значення</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="item.hasOwnProperty('digits')"><td>Номер</td><td>{{ item.digits }}</td></tr>
								<tr v-if="item.hasOwnProperty('operation_group')"><td>Тип реєстрації</td><td>{{ item.operation_group.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('is_last')"><td>Остання реєстрація</td><td>{{ item.is_last? 'ТАК' : 'НІ' }}</td></tr>
								<tr v-if="item.hasOwnProperty('registered_at')"><td>Дата реєстрації</td><td>{{ item.registered_at }}</td></tr>
								
								<tr v-if="item.hasOwnProperty('catalog_model_title')"><td>catalog_model_title</td><td>{{ item.catalog_model_title }}</td></tr>
								<tr v-if="item.hasOwnProperty('catalog_model_slug')"><td>catalog_model_slug</td><td>{{ item.catalog_model_slug }}</td></tr>
								
								<tr v-if="item.hasOwnProperty('vendor')"><td>Марка</td><td>{{ item.vendor }}</td></tr>
								<tr v-if="item.hasOwnProperty('model')"><td>Модель</td><td>{{ item.model }}</td></tr>
								<tr v-if="item.hasOwnProperty('model_year')"><td>Рік</td><td>{{ item.model_year }}</td></tr>
								<tr v-if="item.hasOwnProperty('kind')"><td>Тип</td><td>{{ item.kind.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('body')"><td>Тип кузова</td><td>{{ item.body.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('purpose')"><td>Призначення</td><td>{{ item.purpose.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('color')"><td>Колір</td><td>{{ item.color.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('fuel')"><td>Тип палива</td><td>{{ item.fuel.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('own_weight')"><td>Власна вага</td><td>{{ item.own_weight }}</td></tr>
								<tr v-if="item.hasOwnProperty('total_weight')"><td>Загальна вага</td><td>{{ item.total_weight }}</td></tr>

								<tr v-if="item.hasOwnProperty('is_registered_to_company')"><td>Зареєстровано на організацію</td><td>{{ item.is_registered_to_company? 'ТАК' : 'НІ' }}</td></tr>
								<tr v-if="item.hasOwnProperty('department')"><td>Підрозділ</td><td>{{ item.department }}</td></tr>
								<tr v-if="item.hasOwnProperty('address')"><td>Адреса</td><td>{{ item.address }}</td></tr>
								<tr v-if="item.hasOwnProperty('koatuu')"><td>КОАТУУ</td><td>{{ item.koatuu }}</td></tr>
								<tr v-if="item.hasOwnProperty('displacement')"><td>displacement</td><td>{{ item.displacement }}</td></tr>
								<tr v-if="item.hasOwnProperty('operation')"><td>Коментар</td><td>{{ item.operation.ua }}</td></tr>
							</tbody>
						</template>
					</v-simple-table>
					<br>
					<v-divider></v-divider>
				</v-card-text>
			</v-card>

			<v-card flat v-if="stolen_details.length">
				<v-card-title class="font-weight-light text-subtitle-1">Інформація про викрадення</v-card-title>
				<v-card-text v-for="(item, index) in stolen_details" :key="index">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left" width="30%">Назва</th>
									<th class="text-left">Значення</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="item.hasOwnProperty('theft_at')"><td>Дата викрадення</td><td>{{ item.theft_at }}</td></tr>
								<tr v-if="item.hasOwnProperty('vendor_title')"><td>Тип реєстрації</td><td>{{ item.vendor_title }}</td></tr>
								<tr v-if="item.hasOwnProperty('color')"><td>Колір</td><td>{{ item.color.title.ua }}</td></tr>
								<tr v-if="item.hasOwnProperty('raw_color')"><td>Колір</td><td>{{ item.raw_color }}</td></tr>
								<tr v-if="item.hasOwnProperty('car_type')"><td>Тип</td><td>{{ item.car_type }}</td></tr>
								<tr v-if="item.hasOwnProperty('chassis_number')"><td>Номер шассі</td><td>{{ item.chassis_number }}</td></tr>
								<tr v-if="item.hasOwnProperty('body_number')"><td>Номер кузова</td><td>{{ item.body_number }}</td></tr>
								<tr v-if="item.hasOwnProperty('department_title')"><td>Підрозділ поліції</td><td>{{ item.department_title }}</td></tr>
							</tbody>
						</template>
					</v-simple-table>

					<v-divider></v-divider>
				</v-card-text>
			</v-card>

			<v-card flat v-if="comments.length">
				<v-card-title class="font-weight-light text-subtitle-1">Коментарі</v-card-title>
				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left" width="10%">ID</th>
									<th class="text-left" width="20%">Назва</th>
									<th class="text-left">Текст</th>
									<th class="text-left" width="10%">Створено</th>
									<th class="text-left" width="10%">Змінено</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in comments" :key="index">
									<td>{{ item.id }}</td>
									<td>{{ item.name }}</td>
									<td>{{ item.text }}</td>
									<td>{{ item.created_at | formatDate }}</td>
									<td>{{ item.updated_at | formatDate }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>

					<v-divider></v-divider>
				</v-card-text>
			</v-card>
		</v-container>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf"
			:item="item"
		></z-pdf>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import downloadExcel from "vue-json-excel"
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zPdf from '@/views/pdf/VehicleEstimate'

export default {
	name: 'BazaDai',
	components: {
		zPdf,
		downloadExcel,
		zVehicleParametersNumber,
	},
	data: () => ({
		title: 'Інформація з єдиного державного реєстру транспортних засобів',
		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	
	computed: {
		...mapGetters('BazaDai', [ 'params', 'item', 'isset' ]),

		excelName() {
			return this.title + (this.params.regnum ? ' ' + this.params.regnum : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excelType)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		// Дані про державну реєстрацію транспортного засобу
		registrations() {
			let results = []
			
			/* if(this.item?.digits) results.push({ name: 'Номер', val: this.item.digits });
			if(this.item?.vin) results.push({ name: 'VIN', val: this.item.vin });
			if(this.item?.region) results.push({ name: 'Регіон', val: this.item.region.name_ua });
			if(this.item?.vendor) results.push({ name: 'Марка', val: this.item.vendor });
			if(this.item?.model) results.push({ name: 'Модель', val: this.item.model });
			if(this.item?.model_year) results.push({ name: 'Рік', val: this.item.model_year });
			if(this.item?.is_stolen) results.push({ name: 'Ознака викрадення', val: this.item.is_stolen ? 'ТАК' : 'НІ' }); */
			/*
			{
  "digits": "KA0007XB",
  "vin": "WBA7########57838", // VIN-код сейчас доступен только для регистраций c 2021 года
  "region": {
    "name": "г. Киев",
    "name_ua": "м. Київ",
    "slug": "kyiv",
    "old_code": "AA",
    "new_code": "KA"
  },
  "vendor": "BMW",
  "model": "M760LI",
  "model_year": 2021,
  "photo_url": "https://baza-gai.com.ua/catalog-images/bmw/7er/VI (G11-G12) Рестайлинг/image.jpg",
  "is_stolen": false,
  "stolen_details": null, // пример данных об украденном авто приведен дальше в документации
  "operations": [
    {
      "is_last": true,
      "catalog_model_title": "LS", // при подписке от 10 тыс. запросов
      "catalog_model_slug": "ls", // при подписке от 10 тыс. запросов
      "body": { // при подписке от 10 тыс. запросов
        "id": 4,
        "ua": "СЕДАН",
        "ru": "Седан"
      },
      "purpose": { // при подписке от 10 тыс. запросов
          "id": 1,
          "ua": "ЗАГАЛЬНИЙ",
          "ru": "Общий"
      },
      "registered_at": "03.04.2021",
      "model_year": 2021,
      "vendor": "BMW",
      "vendor_slug": "bmw",
      "model": "M760LI",
      "operation": {
        "ru": "Первичная регистрация нового ТС, автосалон, ввезено из-за границы",
        "ua": "Первинна реєстрація нового тз придбаного в торгівельній організації, який ввезено з-за кордону"
      },
      "department": "ТСЦ 8047",
      "color": {
        "slug": "gray",
        "ru": "Серый",
        "ua": "Сірий"
      },
      "is_registered_to_company": false,
      "address": "м.Київ, Деснянський",
      "koatuu": 8036400000,
      "displacement": 6592,
      "kind": {
        "id": 1,
        "ru": "Легковой",
        "ua": "Легковий",
        "slug": "car"
      },
      "operation_group": {
        "id": 1,
        "ru": "Первичная регистрация",
        "ua": "Первинна реєстрація"
      }
    },
    ...
  ],
  "comments": [
    {
      "id": 1,
      "name": "Василий",
      "text": "Спасибо, что помогли прикурить авто!",
      "created_at": "2022-11-19T18:49:30.392+02:00",
      "updated_at": "2022-11-19T18:49:30.392+02:00"
    },
    ...
  ]
}
			*/

			return results
			//return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},

		operations() {
			return this.item?.operations ? this.item.operations: [];
		},

		stolen_details() {
			return this.item?.stolen_details ? this.item.stolen_details: [];
		},

		comments() {
			return this.item?.comments ? this.item.comments: [];
		},
	},

	methods: {
		...mapActions('BazaDai', [ 'setItem', 'clear' ]),

		validate() {
			return this.params.number || this.params.vin
		},

		onClear() {
			this.clear()
		},

		onOK() {
			var that = this

			if(this.validate()) {
				this.loading = true

				let endpoint = this.params.number ? `/bazadai/number/${this.params.number}` : `/bazadai/vin/${this.params.vin}`;

				axios.get(endpoint).then(response => {
					console.log(that.$options.name, 'onOK','response.data', response.data)

					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false

					console.log(this.$options.name, 'onOK','item', this.item)
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		}
	},
}
</script>