import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":""}},[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VCardText,[_c(VForm,{ref:"form"},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"VIN-код","outlined":"","dense":"","clearable":"","hide-details":"auto"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==90){ return null; }if(!$event.ctrlKey){ return null; }_vm.params.vin='WAUC8AFC0HN089495'},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.target !== $event.currentTarget){ return null; }return _vm.onOK.apply(null, arguments)}]},model:{value:(_vm.params.vin),callback:function ($$v) {_vm.$set(_vm.params, "vin", $$v)},expression:"params.vin"}})],1)],1)],1)],1)],1)],1),_c(VCardActions,{staticClass:"px-4"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onClear}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.clear')))]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.onOK}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1)],1)],1),_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":"","right":"","mini-variant":"","mini-variant-width":"56"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"large":"","color":"red"},on:{"click":_vm.onPDF}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v("mdi-file-pdf-box")])]}}])},[_c('span',[_vm._v("Експорт PDF")])])],1)],1)],1)],1),_c(VContainer,{staticStyle:{"padding":"64px 0px 0px 256px"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.id ? '№' + _vm.id: ''))]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.subtitle))]),_vm._l((_vm.estimates),function(estimate,index){return _c(VCardText,{key:index},[_c(VSimpleTable,{attrs:{"dense":"","fixed-header":"","height":"480px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Код")]),_c('th',{staticClass:"text-left"},[_vm._v("Назва")])])]),_c('tbody',_vm._l((estimate.equipments),function(equipment,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(equipment.name))]),_c('td',[_vm._v(_vm._s(equipment.description))])])}),0)]},proxy:true}],null,true)})],1)})],2)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('z-pdf',{ref:"pdf",attrs:{"item":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }