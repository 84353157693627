import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VCardActions,{staticClass:"px-4"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onClear}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.clear')))]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.onOK}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1)]},proxy:true}])},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c('z-vehicle-parameters-number',{attrs:{"vin-default":"TMBGP21U432674944"}})],1)],1)],1),_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":"","right":"","mini-variant":"","mini-variant-width":"56"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"large":"","color":"red"},on:{"click":_vm.onPDF}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v("mdi-file-pdf-box")])]}}])},[_c('span',[_vm._v("Експорт PDF")])])],1)],1),_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('download-excel',{attrs:{"data":_vm.excel.data,"name":_vm.excel.name,"type":_vm.excel.type}},[_c(VIcon,_vm._g(_vm._b({attrs:{"large":"","color":"green darken-2","disabled":_vm.excel.disabled}},'v-icon',attrs,false),on),[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Експорт Excel")])])],1)],1)],1)],1),_c(VContainer,{staticStyle:{"padding":"64px 0px 0px 256px"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.title)+" "+_vm._s(_vm.id ? '№' + _vm.id: '')+" ")]),_c(VDivider),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Назва")]),_c('th',{staticClass:"text-left"},[_vm._v("Значення")])])]),_c('tbody',_vm._l((_vm.registrations),function(item){return _c('tr',{key:item.code},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.val))])])}),0)]},proxy:true}])})],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('z-pdf',{ref:"pdf",attrs:{"item":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }