export default {
    namespaced: true,
    state: {
		params: {
            type: { code: 'bazadai' },
		    number: null,
			vin: null,
		},

        item: {

        },
    },
    getters: {
		params(state) {
			return state.params
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},
    },
    actions: {
		clear (context) {
			let d = new Date()
			context.commit('params', {
                type: { code: 'bazadai' },
                number: null,
			    vin: null,
			})

           /*  context.commit('item', {
                registrations: [],
			}) */
		},
    },
}