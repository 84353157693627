<template>
	<v-form ref="form">
		<v-row dense>
			<v-col cols="12">
				<v-text-field
					label="Реєстраційний номер"
					v-model.trim="item.number"
					outlined
					dense
					clearable
					@keyup.ctrl.90="keyupNumber"
					hide-details="auto"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field
					label="VIN-код"
					v-model.trim="item.vin"
					outlined
					dense
					clearable
					@keyup.ctrl.90="keyupVin"
					hide-details="auto"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
export default {
	data () {
		return {
			
		}
	},
	props: {
		module:	{ type: String, default: 'VehicleEstimate'}, // check | market | metodica | exploration | averageprice
		numberDefault:	{ type: String, default: 'ab9999it'},
		vinDefault:		{ type: String, default: 'WAUZZZ4M0HD001505'}, //WAUC8AFC0HN089495
	},

	computed: {
		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},
	},

	methods: {
		keyupNumber(){
			this.item.number = this.numberDefault
		},

		keyupVin(){
			this.item.vin = this.vinDefault
		},
	},
}
</script>