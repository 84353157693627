<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<z-vehicle-parameters-number
						vin-default="TMBGP21U432674944"
					></z-vehicle-parameters-number>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<!-- <v-btn icon   v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">
									<v-icon>mdi-file-pdf-box</v-icon>
								</v-btn> -->
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }} {{ id ? '№' + id: '' }}
				</v-card-title>

				<v-divider></v-divider>

				<v-simple-table dense>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Назва</th>
								<th class="text-left">Значення</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in registrations" :key="item.code">
								<td>{{ item.name }}</td>
								<td>{{ item.val }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf"
			:item="item"
		></z-pdf>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import downloadExcel from "vue-json-excel"
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zPdf from '@/views/pdf/VehicleEstimate'

export default {
	components: {
		zPdf,
		downloadExcel,
		zVehicleParametersNumber,
	},
	data: () => ({
		title: 'Параметри транспортного засобу',
		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.regnum ? ' ' + this.params.regnum : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excelType)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		// Дані про державну реєстрацію транспортного засобу
		registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		validate() {
			return this.params.number || this.params.vin
		},

		onClear() {
			this.clear()
		},

		onOK() {
			var that = this

			if(this.validate()) {
				this.loading = true
				axios.post('/vehicle/estimate/auto/params', this.params).then(response => {
					//console.log('VehicleEstimateCheck', 'OnOK','response.data', response.data)

					that.setItem(response.data)
					that.loading = false
				}, error => {
					console.log('error', error)
					that.loading = false

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		}
	},
}
</script>