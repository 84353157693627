import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from '../views/app.login.vue'
import signup from '../views/app.signup.vue'
import passwordChange from '../views/app.password.change.vue'
import passwordrecovery from '../views/app.password.recovery.vue'
import connection from '../views/app.connection.vue'

import BazaDai from '../views/BazaDai'
import VehicleEstimate from '../views/vehicle.estimate.vue'

import Organizations from '../views/Organizations'
import Roles from '../views/Roles'
import Services from '../views/Services'
import Users from '../views/Users'

import Document from '../views/Document'

import Estimate from '../views/Estimate'
import EstimateAveragePrice from '../views/EstimateAveragePrice'

//import VehicleEstimateCheck from '../views/VehicleEstimateCheck'
import VehicleEstimateIdentify from '../views/VehicleEstimateIdentify'
import VehicleEstimateMarket from '../views/VehicleEstimateMarket'
import VehicleEstimateMetodica from '../views/VehicleEstimateMetodica'
import VehicleEstimateExploration from '../views/VehicleEstimateExploration'
import VehicleAutoParams from '@/views/VehicleAutoParams'
import VehicleAutoAdvert from '@/views/VehicleAutoAdvert'
import VehicleAveragePrice from '@/views/VehicleAveragePrice'
import VehicleAveragePriceMediana from '@/views/VehicleAveragePriceMediana'
import VehicleAveragePriceStatistic from '@/views/VehicleAveragePriceStatistic'
import VehicleAveragePriceAI from '@/views/VehicleAveragePriceAI'
import VehicleAveragePriceCombo from '@/views/VehicleAveragePriceCombo'
import VehicleEquipment from '@/views/VehicleEquipment'
import VehicleVerifyVin from '@/views/VehicleVerifyVin'
import VehicleRequest from '@/views/VehicleRequest'

import RealtyAveragePrice from '@/views/RealtyAveragePrice'
import RealtyRequest from '@/views/RealtyRequest'

import OrganizationStatistic from '@/views/OrganizationStatistic'

import DialogTable from '@/views/components/dialog.table'


Vue.use(VueRouter) 
const routes = [
	//{ path: '*', component: NotFoundComponent },
	{ path: '/', name: 'home', component: home },
	{ path: '/login', name: 'login', component: login },
	{ path: '/signup', name: 'signup', component: signup },
	{ path: '/password/change', name: 'passwordChange', component: passwordChange },
	{ path: '/password/recovery', name: 'passwordrecovery', component: passwordrecovery },
	{ path: '/connection', name: 'connection', component: connection },

	//{ path: '/vehicle/estimate/check',         		name: 'VehicleEstimateCheck',			component: VehicleEstimate, props: { type: 'check', title: 'Ідентифікація транспортного засобу' } },
	//{ path: '/vehicle/estimate/check',         		name: 'VehicleEstimateCheck',			component: VehicleEstimateCheck },
	{ path: '/vehicle/estimate/identify',         		name: 'VehicleEstimateIdentify',		component: VehicleEstimateIdentify },
	//{ path: '/vehicle/estimate/market',         		name: 'VehicleEstimateMarket',			component: VehicleEstimate, props: { type: 'market', title: 'Аналіз ринкових цін транспортного засобу' } },
	{ path: '/vehicle/estimate/market',         		name: 'VehicleEstimateMarket',			component: VehicleEstimateMarket },
	//{ path: '/vehicle/estimate/metodica',       		name: 'VehicleEstimateMetodica', 		component: VehicleEstimate, props: { type: 'metodica', title: 'Оцінка ринкової вартості транспортного засобу' } },
	{ path: '/vehicle/estimate/metodica',       		name: 'VehicleEstimateMetodica', 		component: VehicleEstimateMetodica, props: { type: 'metodica', title: 'Оцінка ринкової вартості транспортного засобу' } },
	//{ path: '/vehicle/estimate/exploration',    		name: 'VehicleEstimateExploration',		component: VehicleEstimate, props: { type: 'exploration', title: 'Автотоварознавче дослідження транспортного засобу' } },
	{ path: '/vehicle/estimate/exploration',    		name: 'VehicleEstimateExploration',		component: VehicleEstimateExploration, props: { type: 'exploration', title: 'Автотоварознавче дослідження транспортного засобу' } },
	{ path: '/vehicle/estimate/auto/params',   			name: 'VehicleAutoParams',				component: VehicleAutoParams },
	{ path: '/vehicle/estimate/auto/advert',   			name: 'VehicleAutoAdvert',				component: VehicleAutoAdvert },
	{ path: '/vehicle/estimate/averageprice',   		name: 'VehicleAveragePrice',			component: VehicleAveragePrice },
	{ path: '/vehicle/estimate/averageprice/mediana',	name: 'VehicleAveragePriceMediana',		component: VehicleAveragePriceMediana },
	{ path: '/vehicle/estimate/averageprice/statistic',	name: 'VehicleAveragePriceStatistic',	component: VehicleAveragePriceStatistic },
	{ path: '/vehicle/estimate/averageprice/ai',   		name: 'VehicleAveragePriceAI',			component: VehicleAveragePriceAI },
	{ path: '/vehicle/estimate/averageprice/combo',   	name: 'VehicleAveragePriceCombo',		component: VehicleAveragePriceCombo },
	{ path: '/vehicle/estimate/equipment',   			name: 'VehicleEquipment',				component: VehicleEquipment },
	{ path: '/vehicle/verify/vin',   					name: 'VehicleVerifyVIN',				component: VehicleVerifyVin },
	{ path: '/drug/estimate/market',            		name: 'DrugEstimateMarket',				component: VehicleEstimate, props: { type: 'market', title: 'Аналіз ринкових цін лікарських засобів' } },
	{ path: '/realty/estimate/averageprice',			name: 'RealtyAveragePrice',				component: RealtyAveragePrice },
	
	{ path: '/document',            					name: 'Document',						component: Document },

	{ path: '/estimate',            					name: 'Estimate',						component: Estimate },
	{ path: '/estimate/drug/averageprice',            	name: 'DrugAveragePrice',				component: EstimateAveragePrice, props: { type: 'DrugAveragePrice', title: 'Середня ціна ЛЗ', subtitle: 'Визначення середньої ціни лікарських засобів' } },

	{ path: '/roles',            						name: 'Roles',							component: Roles },
	{ path: '/services',            					name: 'Services',						component: Services },
	{ path: '/users',            						name: 'Users',							component: Users },
	{ path: '/organizations',            				name: 'Organizations',					component: Organizations },
	{ path: '/vehicle/requests',   						name: 'VehicleRequest',					component: VehicleRequest, props: { own: false } },
	{ path: '/vehicle/myrequests',   					name: 'VehicleMyRequest',				component: VehicleRequest, props: { own: true } },
	{ path: '/organization/statistic',   				name: 'OrganizationStatistic',			component: OrganizationStatistic },
	{ path: '/realty/requests',   						name: 'RealtyRequest',					component: RealtyRequest, props: { own: false } },
	{ path: '/bazadai',   								name: 'BazaDai',						component: BazaDai, props: { own: false } },
	
	
	/* {
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/About.vue')
	}, */
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,

	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		}
	}
})

export default router
