<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card class="mx-auto" elevation="0">
				<v-card-text>
					<v-form ref="form">
						<v-card  elevation="0">
							<!-- <v-system-bar class="my-2">
								<span>Параметри</span>
								<v-spacer></v-spacer>
							</v-system-bar> -->

							<v-card elevation="0">
								<v-row dense>
									<v-col cols="12" sm="12" md="12">
										<v-text-field
											label="VIN-код"
											v-model="params.vin"
											outlined
											dense
											clearable
											@keyup.ctrl.90="params.vin='WAUC8AFC0HN089495'"
											@keyup.enter.self="onOK"
											hide-details="auto"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card>
						</v-card>
					</v-form>
				</v-card-text>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</v-card>
			<!-- <template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="clear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template> -->
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				 <!-- <v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item> -->
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<!-- <v-card-title class="font-weight-light">{{ title }}</v-card-title> -->
				<v-card-title class="text-h5">{{ title }} {{ id ? '№' + id: '' }}</v-card-title>
				<v-card-subtitle>{{ subtitle }}</v-card-subtitle>

				<!-- <v-card-text class="d-flex justify-space-between mb-6">
					<span>{{ brand }} {{ model }}</span> 
					<span>{{ (makeDate?'Дата виробництва ':'') }} {{ makeDate }}</span>
				</v-card-text> -->

				<!-- <v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>VIN</td>
									<td>{{ vin }}</td>
								</tr>
								<tr>
									<td>Марка</td>
									<td>{{ brand }}</td>
								</tr>
								<tr>
									<td>Модель</td>
									<td>{{ model }}</td>
								</tr>
								<tr>
									<td>Дата виробництва</td>
									<td>{{ makeDate }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text> -->

				<!-- <v-divider></v-divider> -->
				<!-- <v-card-title class="text-subtitle-1 font-weight-bold">
					Комплектація
				</v-card-title> -->
				<!-- equipment -->
				
				<v-card-text v-for="(estimate, index) in estimates" :key="index">
					<v-simple-table dense
						fixed-header
						height="480px"
					>
						<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Код</th>
								<th class="text-left">Назва</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(equipment, index) in estimate.equipments" :key="index">
								<td>{{ equipment.name }}</td>
								<td>{{ equipment.description }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf" :item="item"></z-pdf>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import zPdf from '@/views/pdf/VehicleEstimate'
import downloadExcel from "vue-json-excel";

export default {
	components: {
		zPdf,
		downloadExcel
	},
	data: () => ({
		title: 'Комплектація',
		subtitle: 'Інформація про складові частини транспортного засобу за VIN-кодом',
		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		excelType: 'xlsx',

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	props: {
		
	},

	watch: {
		
	},
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		/* ...mapGetters('VehicleVerifyVin', [
			'params', 'vin', 'brand', 'model', 'makeDate','options'
		]), */

		excelName() {
			return this.title + (this.vin ? ' ' + this.vin : '') + '.' +  this.excelType
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		onClear() {
			//this.$refs.parameters.clear()
			this.clear()
		},

		validate() {
			return this.params.vin
		},

		/* ...mapMutations('VehicleVerifyVin',{
			setVin: 'vin',
			setBrand: 'brand',
			setModel: 'model',
			setMakeDate: 'makeDate',
			setOptions: 'options',
		}),

		...mapActions('VehicleVerifyVin', [
			'clear'
		]), */

		/* onOK() {
			var that = this

			if(this.params.vin) {
				this.loading = true
				axios.get('/vehicle/verify/vin/' + this.params.vin).then(response => {
					console.log('VehicleVerifyVin', 'search','response.data', response.data)
					//that.item = response.data;
					
					if(response.data?.vin) {
						that.setVin(response.data.vin)
					}

					if(response.data?.brand) {
						that.setBrand(response.data.brand)
					}

					if(response.data?.model) {
						that.setModel(response.data.model)
					}

					if(response.data?.makeDate) {
						that.setMakeDate(response.data.makeDate)
					}

					if(response.data?.options) {
						that.setOptions(response.data.options)
					}
				}, error => {
					//console.log(error)
					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				that.snackbar.visible 	= true;
				that.snackbar.text 		= 'Відсутні дані для пошуку';
				that.snackbar.color 	= null;
			}
		}, */

		onOK() {
			if(this.validate()) {
				this.loading = true
				var that = this
				axios.post('/vehicle/estimate/equipment', this.params).then(response => {
					//console.log(that.$options.name, 'onOK','response.data', response.data)
					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		},
	},
}
</script>