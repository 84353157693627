import Vue from 'vue'
import Vuex from 'vuex'
import {HTTP} from './http'
import axios from 'axios'
import { createStore } from 'vuex-extensions' //https://www.npmjs.com/package/vuex-extensions

import app from './modules/app'
import apikey from './modules/apikey'
import menu from './modules/menu'
import profiles from './modules/profiles'
import Roles from './modules/Roles'
//import users from './modules/users'
import Users from './modules/Users'
import Services from './modules/Services'

import contracts from './modules/contracts'

import BazaDai from './modules/BazaDai'
import CurrencyType from './modules/CurrencyType'
import Document from './modules/Document'
import DocumentMember from './modules/DocumentMember'
import DocumentMemberType from './modules/DocumentMemberType'
import DocumentObject from './modules/DocumentObject'
import DocumentObjectCategory from './modules/DocumentObjectCategory'
import DocumentObjectMember from './modules/DocumentObjectMember'
import DocumentObjectMemberType from './modules/DocumentObjectMemberType'
import DocumentObjectPrice from './modules/DocumentObjectPrice'
import DocumentObjectPriceType from './modules/DocumentObjectPriceType'
import DocumentObjectType from './modules/DocumentObjectType'
import DocumentProperty from './modules/DocumentProperty'
import DocumentPropertyType from './modules/DocumentPropertyType'
import DocumentStatus from './modules/DocumentStatus'
import DocumentStatusType from './modules/DocumentStatusType'
import DocumentType from './modules/DocumentType'

import Organization from './modules/Organization'
import Estimate from './modules/Estimate'
import EstimateType from './modules/EstimateType'
import EstimateObjectType from './modules/EstimateObjectType'
import EstimateAveragePrice from './modules/EstimateAveragePrice'
import vehicles from './modules/vehicles'
import Vehicle from './modules/Vehicle'
import VehicleRequest from './modules/VehicleRequest'
import VehicleEstimate from './modules/VehicleEstimate'
import VehicleEstimateCheck from './modules/VehicleEstimateCheck'
import VehicleEstimateMarket from './modules/VehicleEstimateMarket'
import VehicleEstimateMetodica from './modules/VehicleEstimateMetodica'
import VehicleEstimateExploration from './modules/VehicleEstimateExploration'
import VehicleAveragePrice from './modules/VehicleAveragePrice'
import VehicleVerifyVin from './modules/VehicleVerifyVin'

import State from './modules/State'
import City from './modules/City'
import CityDistrict from './modules/CityDistrict'
import RealtyCategory from './modules/RealtyCategory'
import RealtyOperationType from './modules/RealtyOperationType'
import RealtyType from './modules/RealtyType'
import RealtyRequest from './modules/RealtyRequest'

import RealtyEstimate from './modules/RealtyEstimate'


Vue.use(Vuex)

//export default new Vuex.Store({
export default createStore(Vuex.Store, {
    modules: {
        //alert,
        app,
        apikey,
        BazaDai,
        menu,
        profiles,
        Roles,
        Users,
        Services,
        contracts,
        CurrencyType,
        Document,
        DocumentMember,
        DocumentMemberType,
        DocumentObject,
        DocumentObjectCategory,
        DocumentObjectMember,
        DocumentObjectMemberType,
        DocumentObjectPrice,
        DocumentObjectPriceType,
        DocumentObjectType,
        DocumentProperty,
        DocumentPropertyType,
        DocumentStatus,
        DocumentStatusType,
        DocumentType,
        Organization,
        Estimate,
        EstimateType,
        EstimateObjectType,
        EstimateAveragePrice,
        vehicles,
        Vehicle,
        VehicleRequest,
        VehicleEstimate,
        VehicleEstimateCheck,
        VehicleEstimateMarket,
        VehicleEstimateMetodica,
        VehicleEstimateExploration,
        VehicleAveragePrice,
        VehicleVerifyVin,
        State,
        City,
        CityDistrict,
        RealtyCategory,
        RealtyOperationType,
        RealtyType,
        RealtyEstimate,
        RealtyRequest,
    },
    state: {
        holder: {},         // Поточник користувач, власник процесу
		categories: [],     // Типи транспорту (Категорії)
		fuels: [],		    // Типи палива
		colors: [], 	    // Кольори
		countries: [], 	    // Країни виробники
		states: [], 	    // Області
		marketregions: [], 	// Ринки регіону

		/* message: {
            visibe: false,
            type: 'error',
            title: null,
            text: null,
            status: null,
        }, */

		messageVisibe: false,
		messageType: null,
		messageTitle: null,
		messageText: null,
		messageStatus: null,


    },
    getters: {
		holder(state) {
			return state.holder
		},

        authorized(state) {
			return state.holder?.id ? true : false
		},

		profile(state) {
			return state.holder?.profile ? state.holder.profile : {}
		},

		roles(state) {
			return state.holder?.roles ? state.holder.roles : []
		},

		usermenu(state) {
			var items = []
			if(state.holder?.usersroles) {
				for(let i in state.holder.usersroles) {
					let role = state.holder.usersroles[i]
					if(role.hasOwnProperty('menu')) {
						for(let j in role.menu) {
							let menu = role.menu[j]
							items.push({
								id: menu.id,
								title: menu.name,
								subtitle: menu.description,
								icon: menu.image,
								ordinal: menu.ordinal,
								//to: menu.path,
								router: menu.router,
							})
						}
					}
				}
			}
			
			// DISTINCT
			const unique  = [...new Map(items.map(item => [item['id'], item])).values()];

            //const sort = unique.sort(function (a, b) {return parseInt(a.ordinal) - parseInt(b.ordinal)});
            //console.log('store.index', 'getters','usermenu', sort)

			//return unique;
            return unique.sort(function (a, b) {return parseInt(a.ordinal) - parseInt(b.ordinal)});
		},

		services(state) {
			var items = []

			// Сервіси користувача
            if(state.holder?.services) {
				for(let i in state.holder.services) {
					let item = state.holder.services[i]
					items.push({
						id: item.id,
						odr: item.odr,
						overline: item.type.name,
						title: item.name,
						subtitle: item.description,
						icon: item.image,
						color: item.type.color,
						router: item.router,
					})
				}
			}

			// Сервіси ролей
			if(state.holder?.usersroles) {
				for(let i in state.holder.usersroles) {
					let role = state.holder.usersroles[i]

					if(role.hasOwnProperty('services')) {
						for(let j in role.services) {
							let item = role.services[j]
							items.push({
								id: item.id,
								odr: item.odr,
								overline: item.type.name,
								title: item.name,
								subtitle: item.description,
								icon: item.image,
								color: item.type.color,
								router: item.router,
							})
						}
					}
				}
			}

			// DISTINCT
			const unique  = [...new Map(items.map(item => [item['id'], item])).values()];
			
			//console.log('store.index', 'getters','services', unique)

			return unique.sort(function (a, b) {return parseInt(a.odr) - parseInt(b.odr)});
		},

		categories(state) {
			return state.categories
		},
		fuels(state) {
			return state.fuels
		},
		colors(state) {
			return state.colors
		},
		countries(state) {
			return state.countries
		},
		states(state) {
			return state.states
		},
		marketregions(state) {
			return state.marketregions
		},

		/* message(state) {
			return state.message;
		}, */

		messageVisibe(state) {
			return state.messageVisibe;
		},
		messageType(state) {
			return state.messageType;
		},
		messageTitle(state) {
			return state.messageTitle;
		},
		messageText(state) {
			return state.messageText;
		},
		messageStatus(state) {
			return state.messageStatus;
		},


    },
    mutations: {
		holder (state, data) {
			state.holder = data?data:{}
		},
		categories (state, data) {
			state.categories = data?data:[]
		},
		fuels (state, data) {
			state.fuels = data?data:[]
		},
		colors (state, data) {
			state.colors = data?data:[]
		},
		countries (state, data) {
			state.countries = data?data:[]
		},
		states (state, data) {
			state.states = data?data:[]
		},
		marketregions (state, data) {
			state.marketregions = data?data:[]
		},

		/* message (state, data) {
			state.message = data;

			console.log('store.index', 'message','state.message', state.message)
		}, */

		messageVisibe (state, data) {
			state.messageVisibe = data;
		},
		messageType (state, data) {
			state.messageType = data;
		},
		messageTitle (state, data) {
			state.messageTitle = data;
		},
		messageText (state, data) {
			state.messageText = data;
		},
		messageStatus (state, data) {
			state.messageStatus = data;
		},
    },
    actions: {
		setHolder (context, data) {
			context.commit('holder', data)
		},
		setCategories (context, data) {
			context.commit('categories', data)
		},
		setFuels (context, data) {
			context.commit('fuels', data)
		},
		setColors (context, data) {
			context.commit('colors', data)
		},
		setCountries (context, data) {
			context.commit('countries', data)
		},
		setStates (context, data) {
			context.commit('states', data)
		},
		setMarketregions (context, data) {
			context.commit('marketregions', data)
		},

		closeMessage(context) {
			context.commit('messageVisibe', false)
			/* context.commit('messageType', null)
			context.commit('messageTitle', null)
			context.commit('messageText', null)
			context.commit('messageText', null) */
		},

		showMessage (context, data) {
			//console.log('store.index', 'showMessage','data', data)

            context.commit('messageVisibe', false)

			let type = 'info', title, text, status;

			if(data.hasOwnProperty('response')) {
				if(data.response.hasOwnProperty('data')) {
					//console.log('store\\index', 'showMessage','response.data', data.response.data)
					if(data.response.data.hasOwnProperty('code')) {
						status = data.response.data.code
					}
					if(data.response.data.hasOwnProperty('message')) {
						text = data.response.data.message
					}
				}
			}

			if(data.hasOwnProperty('type')) {
				type = data.type
			}

			if(data.hasOwnProperty('title')) {
				title = data.title
			}

			if(data.hasOwnProperty('text')) {
				text = data.text
			}

			if(data.hasOwnProperty('status')) {
				status = data.status
			}

			//Informational responses
			if(status >= 100 && status <= 199)	type = 'info'
			// Successful responses
			if(status >= 200 && status <= 299) 	type = 'success'
			// Redirection messages
			if(status >= 300 && status <= 399)  type = 'success'
			// Client error responses
			if(status >= 400 && status <= 499)  type = 'error'
			// Server error responses
			if(status >= 500 && status <= 599)  type = 'error'

			switch (type) {
				case "error": 	title = "Error"; 
				case "info": 	title = "Information";
				case "success": title = "Success";
				default:  		title = "Warning";
			}

			//console.log('store.index', 'showMessage','status', status, 'type', type)


			context.commit('messageType', type)
			context.commit('messageTitle', title)
			context.commit('messageText', text)
			context.commit('messageStatus', status)
			context.commit('messageVisibe', true)
			
			/* context.commit('message', {
				visibe: true,
				type: type,
				title: title,
				text: text,
				status: status,
			}) */
		}
    },
	mixins: {
        getters: {
			icon(state) {
				return state.icon
			},
			title(state) {
				return state.title
			},
			headers(state) {
				return state?.headers ? state.headers : []
			},
			endpoint(state) {
				return state.endpoint
			},
			item(state) {
				return state.item
			},
			items(state) {
				return state.items
			},
			filter(state) {
				return state.filter
			},
			types(state) {
				return state.types
			},
        },
        mutations: {
            endpoint (state, data) {
				state.endpoint = data
			},
            item (state, data) {
				state.item = data
			},
			items (state, data) {
				state.items = data
			},
            filter (state, data) {
				state.filter = data
			},
			types (state, data) {
				state.types = data
			},
        },
        actions: {
			clearItem(context) {
				context.commit('item', {
					id: null,
				})
			},

            setItem(context, data) {
				if(data) {
					context.commit('item', data)
				} else {
					context.dispatch('clearItem');
				}
			},
	
			setItems(context, data) {
				if(data) {
					context.commit('items', data)
				} else {
					context.commit('items', [])
				}
			},
	
            addItem(context, data) {
				context.getters.items.push(data)
			},

			createItem(context, data) {
				context.getters.items.push(data)
			},
	
			updateItem (context, data) {
				if(data) {
					if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
						const index = context.getters.items.indexOf(data.old)
						context.getters.items.splice(index, 1, data.new)
					}
				}
			},
	
            removeItem (context, data) {
				const index = context.getters.items.indexOf(data)
				context.getters.items.splice(index, 1);
			},

			deleteItem (context, data) {
				const index = context.getters.items.indexOf(data)
				context.getters.items.splice(index, 1);
			},

            setFilter (context, data) {
                context.commit('filter', data)
            },

            find (context, id) {
                if(id) {
                    return axios.get(`${context.getters.endpoint}/${id}`).then(response => {
                        //console.log(that.$options.name, 'find','response.data', response.data)
                        context.commit('item', response.data)
                    }, error => {
                        console.log(error)
                    })
                }
            },
    
            load (context, reload) {
                if(context.getters.items.length == 0 || reload) {
                    return axios.get(context.getters.endpoint).then(response => {
                        //console.log(this.$options.name, 'onSearch','response.data', response.data)
                        context.commit('items', response.data)
                    }, error => {
                        console.log(error)
                    })
                }
            },

            search (context, params) {
                //return axios.get(`/${context.getters.endpoint}`, { params: params }).then(response => {
                return axios.get(context.getters.endpoint, { params: params }).then(response => {
                    //console.log(this.$options.name, 'onSearch','response.data', response.data)
                    context.commit('items', response.data)
                }, error => {
                    console.log(error)
                })
            },
    
            create (context, data) {
                return axios.post(context.getters.endpoint, context.getters.item).then(response => {
                    //console.log(this.$options.name, 'onSearch','response.data', response.data)
                    context.commit('item', response.data)
                    context.getters.items.push(response.data)
                }, error => {
                    console.log(error)
                })
            },
    
            update (context, selected) {
                return axios.put(`${context.getters.endpoint}/${selected.id}`, context.getters.item).then(response => {
                    //console.log(this.$options.name, 'onSearch','response.data', response.data)
                    context.commit('item', response.data)
    
                    const index = context.getters.items.indexOf(selected)
                    context.getters.items.splice(index, 1, context.getters.item)
                }, error => {
                    console.log(error)
                })
            },

            /* save (context, selected) {
                if(selected?.id) {
                    return 
                }
            } */
        },
    },
})

/*
export default createStore(Vuex.Store, {
    state: {
        name: 'main',
        title: {
            single: 'role',
            plural: 'roles'
        },
        icon: 'mdi-account-tie',

        message: {
            type: 'success',            
            text: null,
        },

        items: [],
    },
    getters: {
        name(state) {
            return state.name;
        },
        module(state) {
            return state.name;
        },
        path(state) {
            return '/'+state.name;
        },
        icon(state) {
            return state.icon;
        },
        title(state) {
            return state.title;
        },
        items(state) {
            return state.items;
        },
        headers(state) {
            return state.headers;
        },
    },
    mutations: {
        items (state, data) {
            state.items = data?data:[]
        },

        insert (state, data) {
            if(Array.isArray(data)) {
                for(var i in data) {
                    state.items.push(data[i]);
                }
            } else {
                state.items.push(data);
            }
        },
        update (state, data) {
            if(data) {
                if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
                    const index = state.items.indexOf(data.old)
                    state.items.splice(index, 1, data.new)
                }
            }
        },
        delete (state, data) {
            if(Array.isArray(data)) {
                for(let i in data) {
                    const index = state.items.indexOf(data[i])
                    state.items.splice(index, 1);
                }
            } else {
                const index = state.items.indexOf(data)
                state.items.splice(index, 1);
            }
        },
    },
    actions: {
        Clear (context){
            //console.log(context.getters.name,'Clear')
            context.commit('items')
        },

        Create(context, data) {
            //console.log(context.getters.name,'Create', 'data', data)
            HTTP.post(context.getters.path, data.item, data.config).then(response => {
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //console.log(context.getters.name,'Create - error', error)
                context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        Read(context, data) {
            console.log(context.getters.name,'Read', 'data', data)

            HTTP.get(context.getters.path, {params: data.params}).then(response => {
                console.log(context.getters.name,'Read', 'response.data', response.data)
                context.commit('items', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //console.log(context.getters.name,'Read - error', error)
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        Update(context, data) {
            HTTP.put(context.getters.path + '/' + data.item.id, data.item).then(response => {
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        Delete(context, data) {
            //setTimeout(() => {
            //    if(data.hasOwnProperty('success')) data.success({});
            //}, 1000)
            HTTP.delete(context.getters.path + '/' + data.item.id).then(response => {
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        //-------------------------------------------------------------------------
        Message(context, data) {
            var message = data
            if(data.hasOwnProperty('response')) {
                let status = data.response.status

                //console.log(context.getters.name,'Message - status', status)

                switch(status) {
                    case 401: message = {
                        type: 'error',
                        text: 'Ви не авторизовані в системі! Для подальшої роботи вам необхідно пройти процедуру авторизації.',
                    }
                    break; 
                    default: message = {
                        type: 'error',
                        text: data.response.data.message,
                    }
                    //console.log(context.getters.name,'Message - response.data', data.response.data)
                }
            }
            context.commit('message', message);
        },
    },
    modules: {
        app,
        apikey,
        menu,
        profiles,
        Roles,
        users,
        services,
        contracts,
        vehicles,
        Vehicle,
        VehicleEstimateCheck,
        VehicleEstimateMarket,
        VehicleEstimateMetodica,
        VehicleEstimateExploration,
        VehicleAveragePrice,
        VehicleVerifyVin,
    },

    mixins: {
        getters: {
            name(state) {
                return state.name;
            },
            module(state) {
                return state.name;
            },
            path(state) {
                return '/'+state.name;
            },
            title(state) {
                return state.title;
            },
            icon(state) {
                return state.icon;
            },
            item(state) {
                return state.item;
            },
            items(state) {
                return state.items;
            },
            headers(state) {
                return state.headers;
            },
            form(state) {
                return state.form;
            },
        },
        mutations: {
            name (state, data) {
                state.name = data;
            },
            item(state, data) {
                state.item = data?data:{}
            },
            items (state, data) {
                state.items = data?data:[]
            },
            insert (state, data) {
                if(Array.isArray(data)) {
                    for(var i in data) {
                        state.items.push(data[i]);
                    }
                } else {
                    state.items.push(data);
                }
            },
            update (state, data) {
                if(data) {
                    if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
                        const index = state.items.indexOf(data.old)
                        state.items.splice(index, 1, data.new)
                    }
                }
            },
            delete (state, data) {
                if(Array.isArray(data)) {
                    for(let i in data) {
                        const index = state.items.indexOf(data[i])
                        state.items.splice(index, 1);
                    }
                } else {
                    const index = state.items.indexOf(data)
                    state.items.splice(index, 1);
                }
            },
        },
        actions: {
            // Встановити виділений елемент
            select(context, data) {
                context.commit('item', data);

                //console.log(context.getters.name, 'select','item', context.getters.item)
            },
            
            // Додати до списку
            add (context, data) {
                context.commit('insert', data)
            },

            remove (context, data) {
                context.commit('delete', data)
            },

            //-------------------------------------------------------------------------------
            find(context, data) {
                //console.log(context.getters.name, 'find','data', data)
                HTTP.get(context.getters.path + '/' + data.id, {params: data.params}).then(response => {
                    if(data.hasOwnProperty('success')) data.success(response.data);
                }, error => {
                    context.dispatch('Message', error, {root: true});
                    if(data.hasOwnProperty('error')) data.error(error);
                });
            },
    
            search(context, data) {
                //console.log(context.getters.name, 'search','data', data)
                HTTP.get(context.getters.path, {params: data.params}).then(response => {
                    //console.log(context.getters.name, 'search','response.data', response.data)
                    context.commit('items');
                    context.commit('insert', response.data);
                    if(data.hasOwnProperty('success')) data.success(response.data);
                }, error => {
                    context.dispatch('Message', error, {root: true});
                    if(data.hasOwnProperty('error')) data.error(error);
                });
            },

            read(context, data) {
                //console.log(context.getters.name, 'read','data', data)
                //context.dispatch('Read', data, {root: true});
                HTTP.get(context.getters.path, {params: data.params}).then(response => {
                    //console.log(context.getters.name,'Read', 'response.data', response.data)
                    context.commit('items', response.data);
                    if(data.hasOwnProperty('success')) data.success(response.data);
                }, error => {
                    //console.log(context.getters.name,'Read - error', error)
                    //context.dispatch('Message', error);
                    if(data.hasOwnProperty('error')) data.error(error.response);
                });
            },
            
    
            create(context, data) {
                //console.log(context.getters.name, 'create', 'data', data)
                
                console.log(context.getters.name, 'create', 'item', context.getters.item)
                context.commit('insert', context.getters.item);
                console.log(context.getters.name, 'create', 'items', context.getters.items)
                if(data.hasOwnProperty('success')) data.success(context.getters.item);
            },
    
            update(context, data) {
                //console.log(context.getters.name, 'update','data', data)

                console.log(context.getters.name, 'update', 'item', context.getters.item)
                console.log(context.getters.name, 'update', 'items', context.getters.items)

                
            },
    
            delete(context, data) {
                //console.log(context.getters.name, 'Delete','data', data)

                context.commit('delete', data.item)


            },
        },
    },
})
*/
